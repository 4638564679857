import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import loadable from '@loadable/component'

const Layout = loadable(() => import('../components/Layout'))
const SEO = loadable(() => import('../components/Seo'))
const PageBuilder = loadable(() => import('../components/PageBuilder'))

export const query = graphql`
  query {
    en: sanityGlobalSettings(i18n_lang: {eq: "en"}) {
      homepage {
        title
        _rawPageBuilder(resolveReferences: { maxDepth: 10 })
        seo {
          metaTitle
          description
          image {
            asset {
              gatsbyImageData(height: 1200, width: 600)
            }
          }
        }
      }
    }
    it: sanityGlobalSettings(i18n_lang: {eq: "it"}) {
      homepage {
        title
        _rawPageBuilder(resolveReferences: { maxDepth: 10 })
        seo {
          metaTitle
          description
          image {
            asset {
              gatsbyImageData(height: 1200, width: 600)
            }
          }
        }
      }
    }
  }
`

const Homepage = ({ location, data }) => {
  const language = process.env.GATSBY_SITE_LANGUAGE

  const page = language === 'en' ? data.en.homepage : data.it.homepage  

  const base = {rel: 'alternate', hreflang: 'en', href: 'https://soulvalley.com/'}
  const translation = {rel: 'alternate', hreflang: 'it', href: 'https://soulvalley.it/'}

  const hrefLang = [base, translation]

  return (
    <>
      <SEO title={page.title} metaTitle={page.seo?.metaTitle} description={page.seo?.description} hrefLang={hrefLang} />
      <Layout location={location || ''}>
        <PageBuilder pageBuilder={page._rawPageBuilder} />
      </Layout>
    </>
  )
}

export default Homepage

Homepage.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object,
}
